import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/SEO"
import Layout from "../components/layout/layout"
import BanerProduct from "../components/banerProduct/BanerProduct"

const Dezynfekcja = () => {
  const data = useStaticQuery(graphql`
    query {
      dez: file(relativePath: { eq: "dez.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const dez = data.dez.childImageSharp.fluid

  return (
    <div>
      <SEO title="Dezynfekcja" slug="/dezynfekcja" />
      <Layout>
        <BanerProduct
          fluid={dez}
          fluidMobile={dez}
          header1="materiały do dezynfekcji"
          header2="Saiko-Med"
        >
          <p>
            Saiko-Med to profesjonalna linia preparatów medycznych do
            dezynfekcji rąk, powierzchni, narzędzi oraz różnych przedmiotów.
            Wszystkie produkty marki Saiko-Med są sprawdzone, bardzo skuteczne,
            bezpieczne w użyciu oraz spełniają wymagania sanepidu. Cała linia
            dezynfekcji została wyróżniona nagrodą <b>LAUR KLIENTA 2020.</b>{" "}
            Dodatkowo preparaty : Saiko Żel, Saiko Zid, Saiko Wipes zdobyły
            uznanie w mediach społecznościowych otrzymując wyróżnienie{" "}
            <b>INFLUENCER'S TOP 2020.</b>
          </p>
        </BanerProduct>
      </Layout>
    </div>
  )
}

export default Dezynfekcja
